:root {
  --primary-color: #f34f50;
  --darkGray-color: #858585;
  --lightGray-color: #8a8a8a;
  --white-color: #ffffff;
  --gray-1: #f9fafb;
  --gray-4: #dbdfe1;
  --light-rgb: 248, 249, 250;
  --heading: 60px;
  --paragraph: 16px;
  --small-text: 14px;
}

body {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
}

.microsoft-login {
  border-radius: 20px;
  /* width: 90px; */
}

.heading h1 {
  color: var(--primary-color);
  font-size: 30px;
  font-weight: 600;
  line-height: 60px;
}

.rating-text {
  font-size: 15px;
}

.rating-star {
  font-size: 20px;
}

.heading p {
  color: var(--darkGray-color);
  font-size: var(--paragraph);
  line-height: 24px;
  letter-spacing: 14%;
  font-weight: 600;
}

.authentication-form .form-label {
  color: var(--lightGray-color);
  font-size: var(--small-text);
}

.authentication-form .form-control {
  border-radius: 60px;
  border: 1px solid var(--lightGray-color);
}

.authentication-form .input-group-text {
  border-radius: 60px;
  border: 1px solid var(--lightGray-color);
  background-color: transparent !important;
}

.authentication-form .form-control::placeholder {
  color: var(--lightGray-color);
}

.authentication-form a {
  color: var(--primary-color);
  font-size: var(--small-text);
  font-weight: 700;
  text-decoration: none;
  line-height: 24px;
}

.button-style {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%);
  border: 0.5px solid #a7a7a7 !important;
  border-radius: 60px !important;
  font-size: var(--small-text);
  font-weight: 500;
  line-height: 21px;
  color: var(--white-color);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out !important;
}

.button-style:hover {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
  box-shadow: 0 0 7px 3px rgba(0, 0, 0, 0.2);
}

.activetab {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%);
  border: 0.5px solid #de494b !important;
  border-radius: 60px !important;
  font-size: var(--small-text);
  font-weight: 500;
  line-height: 21px;
  color: var(--white-color);
}

.nonactivetab {
  background: white;
  border: 0.5px solid #a7a7a7 !important;
  border-radius: 60px !important;
  font-size: var(--small-text);
  font-weight: 500;
  line-height: 21px;
  color: var(--darkGray-color);
}

.otp input {
  width: 60px !important;
  height: 60px !important;
  border-radius: 10px;
  border: 1px solid var(--lightGray-color);
}

@media screen and (max-width: 600px) {
  .custom-tabs {
    /* justify-content: center; */
  }
  .custom-tabs .nav-item button {
    padding: 5px 10px 5px 10px;
  }

  .rating-text {
    font-size: 9px;
    font-weight: 500;
  }

  .rating-star {
    font-size: 12px;
  }

  .heading h1 {
    color: var(--primary-color);
    font-size: 30px;
    font-weight: 600;
    line-height: 60px;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

.badge-notify {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%);

  position: absolute;
  top: -5px;
  right: 5px;
  border-radius: 60% !important;
  height: 20px;
  width: 20px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.main-display {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
}

.more::after {
  display: none !important;
}

.react-toggle-track {
  background-color: #f8cdce !important;
}

.react-toggle--checked:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #ecfbe9 !important;
}

.react-toggle--checked .react-toggle-thumb {
  background-color: #19ab27 !important;
  border-color: #19ab27 !important;
  color: #19ab27 !important;
}

.react-toggle-thumb {
  background-color: #ff0000 !important;
  border-color: #ff0000 !important;
}

.react-toggle-track-x {
  width: 20px !important;
  right: 3px !important;
  margin-top: 8px !important;
  color: #ff0000 !important;
  font-weight: 600;
}

.react-toggle-track-check {
  width: 20px !important;
  left: 3px !important;
  margin-top: 8px !important;
  color: #19ab27 !important;
  font-weight: 600;
}

.school-cards:hover {
  transform: scale(1.05) translate(0, -2px);
}

.school-cards:hover {
  transition: transform 0.4s ease-in-out;
}

.schooldetailcards .CircularProgressbar {
  width: 90% !important;
}

.schooldetailcards .CircularProgressbar .CircularProgressbar-text {
  fill: var(--primary-color);
  font-size: 16px;
  font-weight: 700;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #f34f50 !important;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #e7c5c5 !important;
}

.activity-list {
  list-style: none;
  padding-left: 0;
  position: relative;
}

.activity-item {
  position: relative;
  padding-left: 40px;
  margin-bottom: 20px;
}

.activity-circle {
  position: absolute;
  left: 0;
  top: 30%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid var(--primary-color);
  background-color: #fff;
  z-index: 1;
}

.activity-item::before {
  content: "";
  position: absolute;
  left: 9px;
  top: 46%;
  width: 2px;
  height: calc(100%);
  background-color: var(--lightGray-color);
  z-index: 0;
}

.activity-item:last-child::before {
  display: none;
}

.activity-content {
  position: relative;
  z-index: 2;
}

.rc-progress-line {
  background-color: white !important;
  border-radius: 20px !important;
}

.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.nested-report-listing {
  list-style-image: url("./assets/greencheck.svg");
}

.report-listing::marker {
  color: #303972;
  font-size: 22px;
  font-weight: 700;
  line-height: 60px;
}

.spinner-img {
  width: 200px;
  height: auto;
}

.stsearch-box input {
  border: none !important;
}

.stdropdown-container {
  border-radius: 30px !important;
}

.stsearch-box {
  padding: 3px 5px !important;
}

.notifications .dropdown-toggle::after {
  display: none !important;
}

.nochat {
  background-image: url("./assets/noChat.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.page-link {
  height: 50px;
  width: 50px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  color: #a098ae !important;
}

.page-item:first-child .page-link {
  border-radius: 60px !important;
}

.page-item:last-child .page-link {
  border-radius: 60px !important;
}

.pagination .active .page-link {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%) !important;
  color: #ffffff !important;
  border-color: #de494b !important;
}

.breadcrumb .breadcrumb-item a {
  color: var(--darkGray-color) !important;
  text-decoration: none;
}

.breadcrumb .active {
  color: var(--primary-color) !important;
  font-weight: 600;
}

.breadcrumb {
  margin: 0 !important;
}

.react-international-phone-input {
  width: 100% !important;
}

.react-international-phone-country-selector-button {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
}

.react-international-phone-input {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}

@media (max-width: 991px) {
  .sidebar {
    display: none;
  }

  .schooldetailcards .CircularProgressbar {
    width: 75% !important;
  }

  .bg-progress {
    right: -11px !important;
    width: 85% !important;
    top: 46% !important;
  }
}

/* @media (min-width: 991px) {
  .main-sidebar {
    overflow: hidden !important;

  }
  .main-sidebar:hover{
    padding: 20px 10px 0px 0px !important;
    overflow: auto !important;
  }
} */

.table {
  --bs-table-striped-bg: #fef3f3 !important;
}

.contact-img {
  filter: drop-shadow(10px 7px 10px var(--primary-color));
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}

.blur-text {
  filter: blur(4px);
  position: relative;
  z-index: 1;
}

.reading-categories-cards {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: transform 0.3s ease-in-out;
  border-radius: 20px !important;
  overflow: hidden !important;
}

.reading-categories-cards:hover {
  transform: scale(1.05);
}

.rsw-editor {
  min-height: 300px !important;
  border-radius: 20px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.rsw-btn {
  color: #ffffff !important;
}

.rsw-toolbar {
  background-color: #303972 !important;
  padding: 14px 10px !important;
}

.rsw-btn:hover {
  background: rgba(48, 57, 114, 0.1) !important;
}

.question-dropdown::after {
  display: none !important;
}

textarea::placeholder {
  color: white !important;
}

.rsw-dd {
  background-color: white !important;
  padding: 4px !important;
  border: 1px solid white;
  border-radius: 10px;
}

.nav-item .active {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%) !important;
  color: #ffffff !important;
  border-color: #de494b !important;
}

.nav-item .nav-link {
  color: #000000;
}

.nav-item .nav-link:hover {
  color: #000000;
  background-color: transparent !important;
  border-top-color: white;
  border-left-color: white;
  border-right-color: white;
}

.nav-tabs {
  border-color: #de494b !important;
}

.rc-time-picker-input {
  border-radius: 30px !important;
  align-items: center !important;
  font-size: 16px !important;
  min-height: 36px !important;
  color: black !important;
}

.react-datepicker__input-container input {
  border-radius: 30px !important;
  align-items: center !important;
  font-size: 16px !important;
  min-height: 36px !important;
  color: black !important;
  margin-top: 7px !important;
  border: 1px solid #d9d9d9;
}

.multi-select .css-13cymwt-control {
  border: 1px solid #dee2e6 !important;
  border-radius: 30px !important;
}

.css-1p3m7a8-multiValue {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%) !important;
  border-radius: 30px !important;
  color: white !important;
}

.css-9jq23d {
  color: white !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-tagsinput {
  border-radius: 30px !important;
  padding-top: 3px !important;
}

.react-tagsinput-tag {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%) !important;
  border-radius: 30px !important;
  border: none !important;
  color: #ffffff !important;
  display: inline-block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-tag .react-tagsinput-remove {
  color: white !important;
  text-decoration: none !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--primary-color);
}

.answers .form-check-input:checked {
  background-color: #000000 !important;
  border: none !important;
}

.timestamp {
  color: #303972;
}

.timestamp-orange {
  color: #fb7d5b !important;
}

.rbc-time-view {
  background-color: #fbf3f3 !important;
}

.rbc-today {
  background-color: transparent !important;
}

.loader-modal {
  background: linear-gradient(-45deg, #d7eb75, #ffffff, #7ad0ef, #20c39db7);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.btn-canvas {
  background: linear-gradient(90deg,
      rgba(116, 235, 213, 1) 0%,
      rgba(172, 182, 229, 1) 100%);
  border-radius: 10px;
  margin: 5px;
  padding: 5px 10px;
}

.beginner {
  background: #2ddc75;
  color: white;
}

.elementary {
  background: #4dab23;
  color: white;
}

.intermediate {
  background: #d3da3c;
  color: white;
}

.advanced {
  background: #de8f19;
  color: white;
}

.expert {
  background: #db2728;
  color: white;
}

.btn-16 {
  border: none;
  color: #000;
  background: linear-gradient(90deg,
      rgba(94, 124, 179, 1) 18%,
      rgba(44, 119, 255, 1) 100%) !important;
}

.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001 !important;
  transition: all 0.3s ease;
}

.btn-16:hover {
  color: #000;
}

.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.btn-16:active {
  top: 2px;
}

.custom-btn-1 {
  width: 130px;
  height: 40px;
  color: #fff !important;
  border-radius: 5px;
  padding: 10px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.mytooltip>.tooltip-inner {
  max-width: 100%;
}

/* CSS */
.button-62 {
  background: linear-gradient(90deg, #ec7677 0%, #de494b 100%);
  border: 0 !important;
  border-radius: 60px !important;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Roboto, Helvetica, Arial,
    sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-62:not([disabled]):focus {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5),
    0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

.button-62:not([disabled]):hover {
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5),
    -0.125rem -0.125rem 1rem rgba(239, 71, 101, 0.5),
    0.125rem 0.125rem 1rem rgba(255, 154, 90, 0.5);
}

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #fcfcfd;
  border-radius: 5px !important;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395a;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono", monospace;
  height: 40px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow 0.15s, transform 0.15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
    rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #d6d6e7 0 3px 7px inset;
  transform: translateY(2px);
}

.e-gantt-parent-taskbar {
  display: none !important;
}

.e-gantt-child-taskbar-inner-div {
  outline: none !important;
  /* -webkit-box-shadow: -8px 6px 51px -13px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -8px 6px 51px -13px rgba(0, 0, 0, 0.75);
  box-shadow: -8px 6px 51px -13px rgba(0, 0, 0, 0.75); */
}

.google-btn iframe {
  width: 100% !important;
  margin: 0px !important;
}

.haAclf {
  width: 100% !important;
  padding: 0px !important;
}

#googleSignIn {
  width: 100%;
  max-width: 300px;
}

.exercise-select .css-13cymwt-control {
  border-radius: 30px;
}

/* .e-row{
  background-color: grey;
} */

.highlight-listening td {
  background-color: #f1a400;
  /* Orange for Listening */
  color: white;
}

.highlight-reading td {
  background-color: #3357FF;
  /* Blue for Reading */
  color: white;
}

.highlight-writing td {
  background-color: #FF33A6;
  /* Pink for Writing */
  color: white;
}

.lib-tabs .tab-content {
  height: 90%;
  overflow: auto;
}

/* Style for highlighted words */
.highlighted-word {
  background-color: yellow;
  cursor: pointer;
  text-decoration: underline;
}

/* Optional: Styling for the tooltips */
/* span[title] {
  position: relative;
  display: inline-block;
}

span[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 14px;
  z-index: 1000;
} */

.quiz-option:hover {
  background-color: #f1f1f1 !important;
}

.quiz-container {
  background-image: url("./assets/quiz-bg.png");
  background-size: cover;
}

.oldenburg-regular {
  font-family: "Oldenburg", serif;
  font-weight: 400;
  font-style: normal;
}

/* Slide-in animation for quiz card */
@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

/* Apply the slide-in animation to the card */
.slide-in {
  animation: slideInFromRight 0.5s ease-in-out;
}

.main-sidebar {
  overflow: auto;
}

/* 
*::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
  transition: all ease 0.3s;
  background-color: var(--gray-1);
}

*::-webkit-scrollbar-thumb {
  width: 0.25rem;
  height: 0.25rem;
  background-color: var(--gray-4);
}

*:hover::-webkit-scrollbar-thumb {
  background: rgb(var(--light-rgb));
} */

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: #ddd;
}

*::-webkit-scrollbar-thumb {
  background: #919191;
}

.unread-notification {
  background-color: #fff3f4 !important;
}